import React from 'react';
import './App.css';
import { Button, Table } from 'react-bootstrap';
import JSONPretty from 'react-json-pretty';
import 'bootstrap/dist/css/bootstrap.min.css';
import {aspects, reinins} from "./socioData";


class Calculate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }


    render() {

        let trios = [
             {name: "some name", features: [0,2,10],
             groups: [ {name: "g1", aspects: [0,1,2,3]},{name: "g2", aspects: [4,5,6,7]},
                       {name: "g3", aspects: [8,9,10,11]},{name: "g4", aspects: [12,13,14,15]} ]}
         ];
        trios = [];

        aspects.forEach((d1,i1) => aspects.forEach((d2,i2) => {

            if (i1 === i2 || trios.filter(t => (t.features.includes(i1) && t.features.includes(i2))).length > 0)
            {
                //skip
              //  console.log("skip:",d1,d2);
            }
            else
            {
              //  console.log("proceed:",d1,d2);

                let groups = [[],[],[],[]];

                for (let i=0; i<16; i++) {
                    if (d1.value[i] === 1 && d2.value[i] === 1) groups[0].push(i);   // ++ 1kv
                    else
                    if (d1.value[i] === 0 && d2.value[i] === 0) groups[1].push(i);   // -- 2kv
                    else
                    if (d1.value[i] === 1 && d2.value[i] === 0) groups[2].push(i);   // +- 3kv
                    else
                    if (d1.value[i] === 0 && d2.value[i] === 1) groups[3].push(i);   // -+ 4kv
                }

               // console.log("groups",groups);

                if (groups[0].length === 2 && groups[1].length === 2 && groups[2].length === 2 && groups[3].length === 2) {

                    aspects.forEach((d3,i3) => {

                        if (i1 !== i3 && i2 !== i3)
                            if (groups[0].filter(t => d3.value[t] === 1).length === 2 &&
                                groups[1].filter(t => d3.value[t] === 1).length === 2 &&
                                groups[2].filter(t => d3.value[t] === 0).length === 2 &&
                                groups[3].filter(t => d3.value[t] === 0).length === 2)
                            {
                                //found third d

                                trios.push({
                                    name: "", features:[i1,i2,i3], groups: groups.map(g => ({ name: "", aspects: g}))
                                });
                            }
                            else {
                                //    console.log("failed:", d1,d2,d3);
                            }
                    });
                }
            }
        } ));

        console.log("result", trios);


        return <div className="small "><JSONPretty id="json-pretty" data={trios}/></div>;
    }
}

export default Calculate;