import './App.css';
import Content from "./Content";
import Calculate from "./Calculate";
import { Button } from 'react-bootstrap';


function App() {


    let captionRender =
        <div className="d-flex">
            <h3>Соціоніка: малі групи аспектів</h3>
        </div>;

    let footerRender = (
        <div className="footer">
            <p>(c) <a href="https://fb.com/ilyxa3d" target="_black">Ілля Кравченко</a>, 2022
            <br/>
                <span className="small text-secondary">Авторська версія назв груп</span>
            </p>
            <p className="text-white" style={{height: 100+"px"}}>..</p>
        </div>
    );

    return (
        <div className="mainContainer">
            {captionRender}
            <Content/>
            {footerRender}
        </div>
    )
}


export default App;
